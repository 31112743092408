import React, { useLayoutEffect, useRef, useCallback, useState } from "react";
import { Box, CircularProgress, Typography, List } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRedux } from "../../../hooks";
import { createSelector } from "reselect";
import Message from "./Message";
import ForwardModal from "../../../components/ForwardModal";
import { MessagesTypes } from "../../../data";
import { forwardMessage, deleteImage } from "../../../redux/actions";

interface ConversationProps {
  chatUserConversations: any;
  chatUserDetails: any;
  onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  isChannel: boolean;
}

const Conversation = ({
  chatUserDetails,
  chatUserConversations,
  onDelete,
  onSetReplyData,
  isChannel,
}: ConversationProps) => {
  const { t } = useTranslation();
  const { dispatch, useAppSelector } = useRedux();

  const errorData = createSelector(
    (state: any) => state.Chats,
    state => ({
      getUserConversationsLoading: state.getUserConversationsLoading,
      isMessageForwarded: state.isMessageForwarded,
    }),
  );

  const { getUserConversationsLoading, isMessageForwarded } =
    useAppSelector(errorData);
  const messages = chatUserConversations?.messages?.length
    ? chatUserConversations.messages
    : [];

  const ref = useRef<HTMLDivElement | null>(null);

  const scrollElement = useCallback(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, []);

  useLayoutEffect(() => {
    if (messages.length > 0) {
      scrollElement();
    }
  }, [messages, scrollElement]);

  const [forwardData, setForwardData] = useState<
    null | MessagesTypes | undefined
  >();
  const [isOpenForward, setIsOpenForward] = useState<boolean>(false);

  const onOpenForward = (message: MessagesTypes) => {
    setForwardData(message);
    setIsOpenForward(true);
  };

  const onCloseForward = () => {
    setIsOpenForward(false);
  };

  const onForwardMessage = (data: any) => {
    const params = {
      contacts: data.contacts,
      message: data.message,
      forwardedMessage: forwardData,
    };
    dispatch(forwardMessage(params));
  };

  useLayoutEffect(() => {
    if (isMessageForwarded) {
      setIsOpenForward(false);
    }
  }, [isMessageForwarded]);

  const onDeleteImage = (
    messageId: string | number,
    imageId: string | number,
  ) => {
    dispatch(deleteImage(chatUserDetails.id, messageId, imageId));
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: "100%",
        padding: "1rem",
        marginBottom: "1rem",
      }}
    >
      <Box
        key={messages.length}
        ref={ref}
        id="chat-conversation-list"
        sx={{
          overflowY: "auto",
          height: "calc(100vh - 150px)",
          padding: "1rem",
        }}
      >
        {getUserConversationsLoading && (
          <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
            <CircularProgress />
          </Box>
        )}
        <List>
          {messages.length > 0 ? (
            messages.map((message: MessagesTypes, key: number) => {
              const isFromMe = message.meta.sender === "You";
              return (
                <Message
                  message={message}
                  key={key}
                  chatUserDetails={chatUserDetails}
                  onDelete={onDelete}
                  onSetReplyData={onSetReplyData}
                  isFromMe={isFromMe}
                  onOpenForward={onOpenForward}
                  isChannel={isChannel}
                  onDeleteImage={onDeleteImage}
                />
              );
            })
          ) : (
            <Typography variant="body2" color="textSecondary" align="center">
              {t("No messages yet")}
            </Typography>
          )}
        </List>

        {isOpenForward && (
          <ForwardModal
            isOpen={isOpenForward}
            onClose={onCloseForward}
            forwardData={forwardData}
            chatUserDetails={chatUserDetails}
            onForward={onForwardMessage}
          />
        )}
      </Box>
    </Box>
  );
};

export default Conversation;
