import React, { useState, useRef, useEffect, FC } from "react";
import { IconButton, Box, Typography } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import SendIcon from "@mui/icons-material/Send";

interface AudioRecorderProps {
  onSendAudio: (audioBlob: Blob) => void;
  onCancel: () => void;
}

const AudioRecorder: FC<AudioRecorderProps> = ({ onSendAudio, onCancel }) => {
  const [isRecording, setIsRecording] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    startRecording();
    return () => {
      if (audioURL) {
        URL.revokeObjectURL(audioURL);
      }
      clearInterval(timerRef.current!);
    };
  }, []);

  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = event => {
          audioChunks.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/webm",
          });
          setAudioBlob(audioBlob);
          setAudioURL(URL.createObjectURL(audioBlob));
          audioChunks.current = [];
        };
        mediaRecorderRef.current.start();
        startTimer();
      } catch (error) {
        console.error("Erro ao acessar o microfone:", error);
      }
    }
  };

  const startTimer = () => {
    setRecordingTime(0);
    timerRef.current = window.setInterval(() => {
      setRecordingTime(prev => prev + 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsPaused(false);
      stopTimer();
    }
  };

  const pauseRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      stopTimer();
    }
  };

  const resumeRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "paused"
    ) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      startTimer();
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSendAudio = () => {
    if (audioBlob) {
      onSendAudio(audioBlob);
      resetRecorder();
    }
  };

  const resetRecorder = () => {
    setAudioURL(null);
    setAudioBlob(null);
    setIsPlaying(false);
    setRecordingTime(0);
    onCancel();
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
      {audioURL ? (
        <>
          <audio
            ref={audioRef}
            src={audioURL}
            onEnded={() => setIsPlaying(false)}
            hidden
          />
          <IconButton onClick={handlePlayPause}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <Typography variant="body2">{formatTime(recordingTime)}</Typography>
          <IconButton onClick={resetRecorder} color="error">
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={handleSendAudio} color="primary">
            <SendIcon />
          </IconButton>
        </>
      ) : (
        <>
          <Typography variant="body2">{formatTime(recordingTime)}</Typography>
          <IconButton
            onClick={isPaused ? resumeRecording : pauseRecording}
            color="primary"
          >
            {isPaused ? <MicIcon /> : <PauseIcon />}
          </IconButton>
          <IconButton onClick={stopRecording} color="error">
            <StopIcon />
          </IconButton>
          <IconButton onClick={resetRecorder} color="default">
            <DeleteIcon />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default AudioRecorder;
