import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { mockConversations } from "../../../data/mockData";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/messages";
import { ContactTypes, ConversationTypes } from "../../../data/index";
import Cookies from "js-cookie";
import { URLS } from "../../../constants";

interface IndexProps {
  isChannel: boolean;
  contact?: ContactTypes;
  conversation: ConversationTypes;
}

const Index = ({ isChannel, contact, conversation }: IndexProps) => {
  const { userProfile } = useProfile();
  const [messages, setMessages] = useState<MessagesTypes[]>([]);
  const [replyData, setReplyData] = useState<
    null | MessagesTypes | undefined
  >();

  const fetchMessages = async (chatId: string | number) => {
    const apiKey = Cookies.get("api-key");
    if (!apiKey) {
      console.error("API Key não encontrada");
      return;
    }

    try {
      const response = await fetch(`${URLS.CLIENT_URL}/api/get-messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          APIKEY: apiKey,
        },
        body: JSON.stringify({ phone: chatId }),
      });

      if (!response.ok) {
        throw new Error(`Erro: ${response.status}`);
      }

      const data = await response.json();
      console.log("Mensagens recebidas da API:", data);

      if (data.messages) {
        const mappedMessages = data.messages.map((msg: any) => ({
          mId: msg.id,
          text: msg.body,
          time: new Date(msg.timestamp * 1000).toLocaleString(),
          image: msg.hasMedia ? [msg.media.url] : [],
          attachments: [],
          meta: {
            sender: msg.fromMe ? 'You' : contact?.id,
            receiver: msg.fromMe ? contact?.id : 'You',
            sent: msg.fromMe,
            received: !msg.fromMe,
            read: msg.fromMe,
          },
          isFromMe: msg.fromMe,
          replyOf: msg.shouldReply ? { text: msg.body, id: msg.id } : undefined,
        }));
        setMessages(mappedMessages);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error("Erro ao buscar as mensagens:", error);
    }
  };

  useEffect(() => {
    if (contact?.id) {
      fetchMessages(contact.id);
    }
  }, [contact]);

  const onSend = async (data: MessagesTypes) => {
    if (!contact?.id) {
      console.error("ID do contato não definido");
      return;
    }

    // Criação da mensagem local
    const newMessage: MessagesTypes = {
      mId: new Date().getTime(),
      text: data.text || "",
      time: new Date().toISOString(),
      image: data.image || [],
      attachments: data.attachments || [],
      meta: {
        receiver: contact.id as string | number,
        sender: "You",
        sent: true,
        received: false,
        read: true,
      },
      replyOf: replyData || undefined,
    };

    setMessages(prevMessages => [...prevMessages, newMessage]);

    // Agora envia a mensagem para o backend
    const backendPayload = {
      clientID: "5561981405831",
      messageID: String(contact.id),           // Pode usar o mesmo ID da mensagem local
      answeringTo: true,                    // Se há resposta a algo
      chatId: "5561981405831",
      channel: "API_OFFICIAL",                     // Ajuste conforme necessário
      hasMedia: (newMessage.image && newMessage.image.length > 0) || (newMessage.attachments && newMessage.attachments.length > 0),
      originsIA: true,                            // Ajuste se for origem IA ou não
      shouldConvertToSpeech: false,
      shouldReply: true,                    // Se a mensagem é em resposta a outra mensagem
      session: "574d27ee-30e6-49e6-8dd9-949d9c61f230", // Ajuste aqui para sua session real
      data: {
        message: newMessage.text,
        timestamp: new Date().toISOString(),
      },
      media: (newMessage.attachments || []).map(file => ({
        base64Data: file.downloadLink || "",
        filename: file.name || "file",
        mimetype: "application/octet-stream",
        url: ""
      }))
    };

    try {
      const apiKey = Cookies.get("api-key");
      if (!apiKey) {
        console.error("API Key não encontrada");
        return;
      }

      const response = await fetch(`${URLS.CLIENT_URL}/api/receive-message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "APIKEY": apiKey,
        },
        body: JSON.stringify(backendPayload),
      });

      if (!response.ok) {
        throw new Error(`Falha ao enviar mensagem: ${response.statusText}`);
      }

      const result = await response.json();
      console.log("Mensagem enviada ao backend com sucesso:", result);
    } catch (error) {
      console.error("Erro ao enviar mensagem ao backend:", error);
    }
  };

  const onSendAudio = (audioBlob: Blob) => {
    if (!contact?.id) {
      console.error("ID do contato não definido");
      return;
    }

    const audioMessage: MessagesTypes = {
      mId: Date.now(),
      text: "Audio Message",
      time: new Date().toISOString(),
      meta: {
        receiver: contact.id as string | number,
        sender: "You",
        sent: true,
        received: false,
        read: false,
      },
      attachments: [
        {
          id: Date.now(),
          downloadLink: URL.createObjectURL(audioBlob),
          name: "Audio Recording",
          desc: `${audioBlob.size} bytes`,
        },
      ],
      image: undefined,
    };

    setMessages(prevMessages => [...prevMessages, audioMessage]);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <UserHead
        chatUserDetails={contact}
        pinnedTabs={[]}
        onOpenUserDetails={() => {}}
        onDelete={() => {}}
        isChannel={isChannel}
        onToggleArchive={() => {}}
      />

      <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
        <Box
          flexGrow={1}
          sx={{
            maxHeight: "calc(100vh - 150px)",
            overflowY: "auto",
            zIndex: 1,
          }}
        >
          <Conversation
            chatUserConversations={{
              conversationId: String(contact?.id),
              messages,
            }}
            chatUserDetails={contact}
            onDelete={() => {}}
            onSetReplyData={setReplyData}
            isChannel={isChannel}
          />
        </Box>

        <ChatInputSection
          onSend={onSend}
          onSendAudio={onSendAudio}
          replyData={replyData}
          onSetReplyData={setReplyData}
          chatUserDetails={contact}
          isChannel={isChannel}
        />
      </Box>
    </Box>
  );
};

export default Index;
