import React, { FC } from "react";
import { IconButton, Box } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";

interface EndButtonsProps {
  onSubmit: () => void;
  isTextEmpty: boolean;
  isRecording: boolean;
  toggleRecording: () => void;
}

const EndButtons: FC<EndButtonsProps> = ({
  onSubmit,
  isTextEmpty,
  isRecording,
  toggleRecording,
}) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      {!isRecording ? (
        <IconButton
          onClick={isTextEmpty ? toggleRecording : onSubmit}
          sx={{
            color: "#ffffff",
            backgroundColor: "#4e3699",
            borderRadius: "8px",
            "&:hover": { backgroundColor: "#6a4eb4" },
            transition: "background-color 0.2s",
            width: 48,
            height: 48,
          }}
        >
          {isTextEmpty ? <MicIcon /> : <SendIcon />}
        </IconButton>
      ) : null}
    </Box>
  );
};

export default EndButtons;
