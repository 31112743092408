import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import { PlayArrow, Pause } from "@mui/icons-material";
import { formateDate } from "../../../utils";
import { MessagesTypes } from "../../../data/messages";
import { useTranslation } from "react-i18next";

interface MessageProps {
  message: MessagesTypes;
  chatUserDetails: any;
  onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  isFromMe: boolean;
  onOpenForward: (message: MessagesTypes) => void;
  isChannel: boolean;
  onDeleteImage: (messageId: string | number, imageId: string | number) => void;
}

const Message = ({
  message,
  chatUserDetails,
  onDelete,
  onSetReplyData,
  isFromMe,
  onOpenForward,
  isChannel,
  onDeleteImage,
}: MessageProps) => {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState<number | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const date = formateDate(message.time, "hh:mmaaa");

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const updateCurrentTime = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <Box
      display="flex"
      justifyContent={isFromMe ? "flex-end" : "flex-start"}
      p={2}
    >
      {/* Avatar do usuário correspondente */}
      {!isFromMe && (
        <Avatar
          src={chatUserDetails?.profileImage || undefined}
          alt={chatUserDetails?.name || "User"}
          sx={{ mr: 2 }}
        />
      )}

      <Box
        bgcolor={isFromMe ? "primary.main" : "grey.200"}
        color={isFromMe ? "primary.contrastText" : "text.primary"}
        p={2}
        borderRadius={2}
        maxWidth="70%"
      >
        {message.attachments && message.attachments[0]?.downloadLink ? (
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton
              onClick={togglePlay}
              sx={{ color: isFromMe ? "white" : "inherit" }}
            >
              {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
            <audio
              ref={audioRef}
              src={message.attachments[0].downloadLink}
              onTimeUpdate={updateCurrentTime}
              onLoadedMetadata={handleLoadedMetadata}
              onEnded={() => setIsPlaying(false)}
              style={{ display: "none" }}
            />
            <Box flexGrow={1}>
              <Box
                sx={{
                  height: 4,
                  backgroundColor: isFromMe
                    ? "rgba(255,255,255,0.5)"
                    : "grey.400",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: `${(currentTime / (duration || 1)) * 100}%`,
                    height: "100%",
                    backgroundColor: isFromMe ? "white" : "grey.700",
                    transition: "width 0.1s",
                  }}
                />
              </Box>
              <Typography
                variant="caption"
                color={isFromMe ? "white" : "textSecondary"}
              >
                {formatTime(currentTime)} /{" "}
                {duration ? formatTime(duration) : "0:00"}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography variant="body1" gutterBottom>
            {message.text}
          </Typography>
        )}

        <Box display="flex" justifyContent="space-between" mt={1}>
          <Typography variant="caption" color={isFromMe ? "white" : "grey.700"}>
            {date}
          </Typography>
          <IconButton
            size="small"
            onClick={() => onSetReplyData(message)}
            sx={{ color: isFromMe ? "white" : "inherit" }}
          >
            <i className="bx bx-reply"></i>
          </IconButton>
        </Box>
      </Box>

      {isFromMe && (
        <Avatar
          src={chatUserDetails?.profileImage || undefined}
          alt="You"
          sx={{ ml: 2 }}
        />
      )}
    </Box>
  );
};

export default Message;
